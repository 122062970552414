section .toolPanel .selected {
    background-color: var(--bg-3);
    border-radius: 2px;
    transition: background-color 0.3s ease;
}

section .toolPanel svg {
    fill: var(--fg-3);
    width: 100%;
    height: 100%;
}

section .toolPanel>div [role=button]:hover svg {
    fill: var(--fg-2);
}

section.vertical {
    flex-direction: column;
}

section.vertical .toolPanel {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
}


section.horizontal .toolPanel {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
}

section.vertical .toolPanel>div {
    display: flex;

}


section.horizontal .toolPanel>div {
    display: flex;
    flex-direction: column;
}

section .toolPanel>div [role=button] {
    width: 2em;
    height: 2em;
    padding: .3em;
    margin: 0;
}

section.horizontal .toolPanel .viewBoxContainer {
    margin-top: .6em;
    padding-top: .6em;
    border-top: 1px solid #555;
}

section.vertical .toolPanel .viewBoxContainer {
    margin-left: .6em;
    padding-left: .6em;
    border-left: 1px solid #555;
}

section .toolPanel>div {
    margin: 0 0 0 0;
}

section .toolPanel>div>div:not(.toolPanel >div > div:last-child) {
    margin: 0 0 0 0;
}