.pageList>div {
  margin: 0 0.2rem;
  padding: 0.3rem 0.6rem;
  border: 2px solid transparent;
  border-radius: 4px;
  transition: border .1s ease;
}

.pageList>div:hover {
  border: 2px solid var(--fg-1);
}

.pageList>div:first-child,
.pageList>div:last-child {
  border: none;
}

.pageList .current {
  border: 2px solid var(--fg-1);
}