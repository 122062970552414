body .edition_container {
  display: flex;
  flex-direction: row;
  height: 100%;
  /* place-items: center; */
  border-top: 1.5px solid var(--separator);
}

.viewPanel {
  grid-area: viewPanel;
}