.dblCol-nav_container {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.dblCol-nav_container>nav {
  width: 20%;
  max-width: 15em;
  overflow-y: auto;
}

.dblCol-nav_container hr{
  background-color: var(--fg-3);
  border: none;
  height: 1px;
}


.dblCol-nav_container>nav [role=button] {
  display: flex;
  align-items: center;
  padding: 0.5rem .8rem;
  margin: 0;
}

.dblCol-nav_container>nav [role=button] .iconContainer {
	width: 2em;
	height: 2em;
	margin-right: .6em;
}

.dblCol-nav_container>nav [role=button] img,
.dblCol-nav_container>nav [role=button] svg {
	max-width: 2em;
	max-height: 2em;
}

.dblCol-nav_container>nav h2 {
  width: 100%;
  margin: 0 0 .1em 0;
  padding: 2vh 0 2vh;
  text-align: center;
  background-color: var(--bg-3);
  font-size: 1.6rem;
  line-height: 3rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dblCol-nav_container>nav .selected {
  background-color: var(--bg-3);
}

.dblCol-nav_container>div {
  flex-grow: 1;
  width: 100%;
  padding: 2vh 2vw;
  background-color: var(--bg-2);
  overflow-y: auto;
}
