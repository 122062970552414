.edition_container section {
	position: relative;
	display: flex;
	overflow: hidden;
}

.edition_container section * {
	user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

section .workflowViewBox {
	position: relative;
	flex-grow: 1;
	overflow: auto;
}

.workflow {
	position: relative;
	background-color: var(--bg-2);
	background-size: 40px 40px;
}

.workflow.grid {
	background-image:
		repeating-linear-gradient(var(--bg-4) 0 1px, transparent 1px 100%),
		repeating-linear-gradient(90deg, var(--bg-4) 0 1px, transparent 1px 100%);
}

.workflow.grid-dotted {
	background-image: radial-gradient(circle at 1px 1px, var(--bg-4) 1px, transparent 0);
}

.workflow .component {
	position: absolute;
	box-sizing: border-box;
	display: inline-block;
}

.workflow .component,
.workflow .component * {
	user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

.workflow .collisionBox {
	display: block;
	box-sizing: border-box;
}


.workflow .collisionBox .name {
	display: block;
	position: relative;
	max-height: 35%;
	height: 35%;
	margin-top: 5%;
	padding: 1%;
	text-align: center;
	line-height: 1.1;
	background-color: rgba(0, 0, 0, .64);
	/* text-overflow: ellipsis; */
	overflow: hidden;
	z-index: 0;
}

.workflow .collisionBox .name::before {
	position: absolute;
	content: "";
	inset: 0;
	background-color: var(--bg-2);
	opacity: 0.68;
	z-index: -1;
}

.workflow .collisionBox .name textarea {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: max-content;
}

.workflow .component .icon {
	position: relative;
	display: block;
	width: 60%;
	height: 60%;
	border-radius: 50%;
	margin: auto;
	padding: 0;
	cursor: pointer;
}

.workflow .component.inout .icon,
.ov_renameComponent .inout {
	background-color: #E893CF;
}

.workflow .component.visualization .icon,
.ov_renameComponent .visualization {
	background-color: #116D6E;
}

.workflow .component.data .icon,
.ov_renameComponent .data {
	background-color: #526D82;
}

.workflow .component.operation .icon,
.ov_renameComponent .operation {
	background-color: #EA906C;
}

.workflow .component.deleteHighlight .icon:hover::after {
	display: block;
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(255, 0, 0, 0.6);
	border-radius: 50%;
}


.workflow .component.focused .collisionBox .icon::after,
.workflow .component.selected .collisionBox .icon::after,
.workflow .component.pathEdition_start .collisionBox .icon::after,
.workflow .component.pathEdition_incompatible .collisionBox .icon::after,
.workflow .component.pathEdition_compatible .collisionBox .icon::after {
	display: block;
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: 50%;
	pointer-events: none;
}

.workflow .component.focused .collisionBox .icon::after {
	/* border: .15em solid magenta; */
	background: radial-gradient(circle at center, transparent 58%, silver 74%, silver 100%);
}

.workflow .component.selected .collisionBox .icon::after {
	/* border: .15em solid magenta; */
	background: radial-gradient(circle at center, transparent 58%, magenta 74%, magenta 100%);
}

.workflow .component.pathEdition_start .collisionBox .icon::after {
	background: radial-gradient(circle at center, transparent 58%, cyan 74%, cyan 100%);
}

.workflow .component.pathEdition_compatible .collisionBox .icon::after {
	background: radial-gradient(circle at center, transparent 58%, rgba(0, 250, 0, .32) 74%, rgba(0, 250, 0, .32) 100%);
	background-color: rgba(0, 250, 0, .32);
}

.workflow .component.pathEdition_incompatible .collisionBox .icon::after {
	border: 6px solid red;
	background-color: rgba(250, 0, 0, .32);
}


.workflow .component .icon>img,
.ov_renameComponent .icon img,
.ov_renamePath .icon img {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 66%;
	height: 66%;
	margin: 17%;
}

.workflow .component.cannotPlace::before {
	position: absolute;
	display: block;
	content: "";
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: red;
	border-radius: 4px;
	opacity: .6;
}

/*.workflow .component.flyingComponent,*/
.workflow .component img {
	pointer-events: none;
}


.workflow .toolPointerIconContainer {
	position: absolute;
	width: 16px;
	height: 16px;
	transform: translate(-120%, 100%);
}

.workflow .toolPointerIconContainer svg {
	width: 100%;
	height: 100%;
	stroke: var(--fg-1);
	stroke-width: 6px;
	fill: var(--bg-3);
}

#arrowsSVG {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

#arrowsSVG .tempArrow {
	stroke: white;
	stroke-width: 2px;
	fill: none;
}

#arrowsSVG .pathGroup .vPath {
	stroke: orange;
	stroke-width: 2px;
	fill: none;
}

#arrowsSVG .pathGroup.focused .vPath {
	stroke: silver;
	stroke-width: 2px;
}

#arrowsSVG .pathGroup.selected .vPath {
	stroke: magenta;
	stroke-width: 3px;
}

#arrowsSVG .pathGroup .clickArea {
	stroke: transparent;
	stroke-width: .6em;
	fill: none;
	opacity: 0;
	cursor: pointer;
}

#arrowsSVG .pathGroup textPath {
	fill: var(--fg-1);
	stroke: var(--fg-1);
	font-size: .9rem;
}

#arrowsSVG .pathGroup.deleteHighlight .clickArea:hover {
	stroke: rgba(255, 0, 0, 0.6);
	stroke-width: 8px;
	opacity: 1;
}

#arrowsSVG .pathGroup .progression {
	opacity: 0;
	stroke: green;
	stroke-width: px;
	--dashOffset: 90px;
	--dashArray: 90px;
	--negativeDashOffset: -90px;
	stroke-dasharray: var(--dashArray);
	stroke-dashoffset: var(--dashOffset);
	stroke-linecap: round;
	transition-property: stroke-dashoffset, stroke-dasharray;
	transition-duration: 1.5sec;
	transition-timing-function: ease-in;
}

#arrowsSVG .ringPath .vPath,
#arrowsSVG .ringPath .clickArea {
	stroke-width: 0px;
	fill: none;
}


#arrowsSVG .ringPath .progression {
	opacity: 0;
	stroke: yellow;
	stroke-width: 5px;
	--dashOffset: 90px;
	--dashArray: 90px;
	--negativeDashOffset: -90px;
	--dashArrayMaxLength: 180px;
	stroke-dasharray: var(--dashArray);
	stroke-dashoffset: var(--dashOffset);
	stroke-linecap: round;
	transition-property: stroke-dasharray, stroke-dashoffset;
	transition-duration: 1.5s;
	transition-timing-function: ease-in;
}

#arrowsSVG .ringPath .progression.error {
	stroke: red;
}

#arrowsSVG .ringPath .progression.mismatch {
	stroke: grey;
}

.fillPath {
	animation: fillPath 1s linear forwards;
}

.rotatePath {
	animation: strokeRotation 2s linear infinite;
}

.workflow .flying {
	border: 1px dotted #333;
	transition: left .1s ease-out, top .1s ease-out;
}

.workflow .ghost {
	opacity: .5;
}

.workflow .pinIn,
.workflow .pinOut {
	position: absolute;
	width: var(--size);
	height: var(--size);
	transform: translate(-50%, -50%);
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.workflow .pinOut,
.workflow .pinIn {
	width: var(--size);
	height: var(--size);
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.workflow .pinIn svg,
.workflow .pinOut svg {
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.workflow .pinIn path,
.workflow .pinOut svg {
	fill: orange;
	stroke: var(--bg-2);
	stroke-width: 20px;
}


.ov_renameComponent,
.ov_renamePath {
	display: flex;
	align-items: center;
}

.ov_renameComponent .icon,
.ov_renamePath .icon {
	position: relative;
	display: inline-block;
	width: 5em;
	height: 5em;
	border-radius: 50%;
	margin: 3% 5% 3% 3%;
}



.ov_renamePath .path {
	background-color: var(--fg-3);
}

@keyframes fillPath 
{
	70% {
/*		stroke-dasharray: 0; */
		stroke-dasharray: var(--dashArrayMaxLength);
	}
	to
	{
		stroke-dasharray: var(--dashArrayMaxLength);
		stroke-dashoffset: 0;
	}
}

@keyFrames strokeRotation {
  to 
  {
		stroke-dashoffset: var(--negativeDashOffset);
  }
}