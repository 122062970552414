/* .return {
  font-size: 1rem;
  font-weight: 600;
  color: gray;
  padding: 0.2rem 0.4rem;
} */

/* Works on Firefox */
* {
  scrollbar-width: initial;
  scrollbar-color: gray;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

*::-webkit-scrollbar-corner {
  background-color: transparent;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #a4a4a4;
  border-radius: 20px;
}