.documentation_container {
  display: grid;
  grid-template-columns: minmax(300px, 25%) auto;
  grid-template-rows: auto;
  min-height: 100%;

  width: 100%;
  color: var(--fg-1);
  line-height: 1.5;
}

.documentation_container .serviceList h2 {
  text-align: center;
  background-color: var(--bg-3);
  font-size: 1.6rem;
  padding: 0 0.5em;
}

/*.documentation_container .serviceDetail h2 {
  margin-bottom: 0.8em;
}

.documentation_container a {
  color: orange;
}

.documentation_container .serviceDetail h3 {
  margin: 2em 0.5rem 1em;
}

.documentation_container .serviceDetail h4 {
  margin: 1em 1rem 1em;
}

.documentation_container .serviceDetail h5 {
  margin: 2em 1.5rem 0.5em;
}
*/

.documentation_container .serviceDetail.swagger-ui {
	background-color: #FAFAFA !important;
	filter: invert(1) hue-rotate(180deg);
}

.documentation_container .serviceList {
  background-color: var(--bg-2);
}

.documentation_container .serviceList a {
  display: block;
  margin: 0.4em 0;
  padding: 0.2em 1em;
}

.documentation_container .serviceList a.selected {
  background-color: var(--bg-3);
}

.documentation_container .serviceDetail {
  background-color: var(--bg-2);
}

.documentation_container .serviceDetail p {
  margin: 1.5em 1em;
}

.documentation_container .serviceDetail {
  padding: 1em 4%;
}

.documentation_container .serviceDetail>div,
.documentation_container .serviceDetail>p {
  margin-left: 1em;
}

.documentation_container .routeDetail {
  border-bottom: 1px solid var(--separator);
  margin-bottom: 4em;
  padding-bottom: 2em;
}

.documentation_container .routeDetail .description {
  margin-left: 1em;
}

.documentation_container .routeDetail .method {
  margin-right: 1em;
}

.documentation_container .serviceDetail table th {
  padding: 0.8em;
}

.documentation_container .serviceDetail table td {
  padding: 0.4em 1.2em;
}

.documentation_container .routeDetail table {
  margin: 1em;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
}

.documentation_container .routeDetail thead {
  margin-bottom: 2em;
}

.documentation_container .routeDetail thead tr {
  background-color: var(--bg-3);
}

.documentation_container .routeDetail th {
  text-align: left;
}

.documentation_container .routeDetail td,
.documentation_container .routeDetail th {
  padding: 0.2em 0.8em;
}

.documentation_container .serviceDetail thead td {
  font-weight: bold;
}

.documentation_container .routeDetail tbody tr:first-child td {
  padding-top: 0.6em;
}

code {
  display: block;
  border-radius: 4px;
  padding: 0.5em;
  background-color: var(--bg-1);
  color: var(--fg-2);
  min-width: 50%;
  width: max-content;
  max-width: 100%;
}
