/* * GENERAL * */
/* :root {
  --titleFontsize: 2.2rem;
  --textFontSize: 2rem;
  --buttonFontSize: 2rem;
} */

* {
  list-style: none;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}


html {
  font-size: 18px;
  font-family: Roboto, Helvetica, sans-serif;
}

html,
body {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  line-height: 1.6;
}

body{
  display: flex;
  flex-direction: column;
  background-color: var(--bg-1);
  color: var(--fg-1);
}

a {
  color: var(--fg1);
  font-weight: bold;
  text-decoration: none;
}

h1 {
  font-size: 2.2rem;
  font-weight: bold;
}

h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: .5rem;
  padding-left: 1em;
}

h3 {
  font-size: 1.8rem;
  margin-top: 3rem;
}

h4 {
  font-size: 1.6rem;
}

h5 {
  font-size: 1.4rem;
}

p {
  max-width: 50em;
  margin: 1.6em 0;
}

svg {
  stroke: var(--svg-fg-1);
  fill: var(--fg-2);
}

/*CONTAINER*/
#mainContainer {
  flex-grow: 1;
  overflow-y: auto;
  border-top: 1.5px solid var(--separator);
}

.error {
  background-color: var(--alert-bg-1);
  border-radius: 4px;
  font-style: italic;
  color: var(--fg-1);
  text-align: center;
  font-size: .9em;
  padding: 0 .2em;
}

.headerContainer
{
  display: flex;
  flex-direction: row;
/*   margin-bottom: 15px; */
/*   gap: 2px; */
/*   justify-content: flex-start; */
}

.sideNavBarContainer{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
  width: 25%;
  background-color: #111111c7;
  overflow-y: auto;
}

.upperNavBarContainer{
  display: flex;
  flex-grow: 1;
  background-color: #111111c7;
  overflow-x: auto;
}

.textNavHeaderContainer{
  display: flex;
  width: 25%;
}

#breadcrumb{
  display: flex;
  flex-grow: 1;
  background-color: #32465a;
}

.headerElement{
  outline: 0px solid rgba(255, 255, 255, 0.4);
  outline-offset: 0px;
  transition-property: outline, outline-offset;
  transition: 0.25s ease-out;
}

.headerElement:active{
  outline: 3px solid rgba(255, 0, 0, 0.7);
  outline-offset: -2px;
}

.headerElement:hover, .headerElement:focus{
  cursor: pointer;
  outline: 3px solid rgba(255, 255, 255, 0.7);
  outline-offset: -2px;
}

.navElement{
  flex-grow: 1;
  background-color:rgba(0, 0, 0, 0);
}

.hidden{
  display:none;
}

li.navElement{
  display: flex;
  background-color: rgba(0,0,0,0.1); 
}

li.navElement a{
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding-left: 5%;
}

li.navElement a:hover{
  cursor: pointer;
  outline: 3px solid rgba(255, 255, 255, 0.7);
  outline-offset: -2px;
}

#navMenu{
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom:0;
  right: 0;
  left:0;
  z-index: 10;
  background-color: rgba(119, 136, 152, 0.82);
  backdrop-filter: blur(2px);
}

#navMenu.hidden{
  display:none;
}

.topSide{
  display: flex;
/*   height: max-content; */
  width: 100%;
}

#offlineNavBtn{
  background-image: url("/img/logo.webp");
  background-size: contain;
  background-repeat: no-repeat;
  width: 20%;
 }

#offlineHeader>.topSide>ul>li.navElement>a{
  display: flex;
  align-items: center;
  justify-content: center;
}

#offlineHeader.headerContainer{
  height: 15%;
}

#breadcrumb.hidden{
  display: none;
}

.crumbOfBread{
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

#sideNavBar>div{
  display: flex;
  flex-direction: column;
}
/* 
#sideNavBar>div div{
  background-color: rgba(0,0,0,0.1); 
}
*/
#sideNavBar>div>div:hover{
  cursor: pointer;
  outline: 3px solid rgba(255, 255, 255, 0.7);
  outline-offset: -2px;
}

#sideNavBar>div>div{
  gap: 2em;
  align-items: center;
}

#sideNavBar>div>div{
  display: flex;
  flex-grow: 1;
}

#sideNavBar>div{
  flex-grow: 1;
}