._overlay {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(128, 128, 128, 0.76);
	z-index: 1100;
}

._overlay ._ovContainer {
	display: flex;
	flex-direction: column;
	position: absolute;
	left: 50%;
	top: 50%;
	min-width: 30vw;
	width: 97vw;
	max-height: 96vh;
	overflow: auto;
	overflow-x: hidden;
	background-color: var(--bg-2);
	border: 1px solid var(--fg-1);
	border-radius: 3px;
	transform: translate(-50%, -50%);
	box-shadow: 1px 1px 1px 1px var(--fg-3);
	z-index: 1110;
	padding: 1em;
}


._overlay ._ovContainer ._ovTitle {
	padding: 1ex 1em;
	background-color: var(--bg-3);
	font-size: var(--titleFontsize);
	font-weight: bold;
	margin: -1em -1em 2vh -1em;
}

._overlay ._ovContainer ._ovContent {
	flex-grow: 1;
	overflow-y: auto;
	/* scrollbar-color: #30547E black;
	scrollbar-width: thin; */
	font-size: var(--textFontsize);
	min-height: 10vh;
	margin: 1%;
	padding: 1%;
}

._overlay ._ovButtonsContainer {
	display: flex;
	justify-content: space-around;
}

._overlay ._ovContainer ._ovContent input,
._overlay ._ovContainer ._ovContent select,
._overlay ._ovContainer ._ovContent textarea {
	display: block;
	margin: 1rem 0;
	padding: 0.5rem;
	background-color: var(--bg-1);
	border: none;
}

@media only screen and (min-width: 640px) {
	._overlay ._ovContainer {
		width: max-content;
		max-width: 88vw;
		min-width: 40vw;
	}
}


@media only screen and (min-width: 1279px) {
	._overlay ._ovContainer {
		width: max-content;
		max-width: 70vw;
		min-width: 44vw;
	}
}