.viewPanel {
  display: flex;
  flex-direction: column;
}

.viewPanel h2 {
  display: inline-block;
  padding: .2em .5em;
  background-color: var(bg-3);
  font-size: 1.6rem;
}

.viewPanel .viewContainer {
  flex-grow: 1;
  border: 1px solid var(--bg-3);
  background-color: var(--bg-2);
  margin: 0 .3em .3em .3em;
  padding: .2em;
}

.viewPanel.hidden{
  display: none;
}