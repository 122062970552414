[role=button] {
	display: inline-block;
	color: var(--btn-fg);
	cursor: pointer;
	padding: 1em 1.4em;
	margin: .6em;
}


[role=button]:hover {
	background-color: var(--btn-bg-hover);
}

[role=button]:focus {
	outline: none;
	box-shadow: inset 0 0 1px 1px var(--btn-border-focus);
}

[role=button]>._icon {
	width: auto;
	height: 1em;
	margin: 0;
}


[role=button].button {
	font-size: 1rem;
	line-height: 1rem;
	border-radius: 4px;
	background-color: var(--btn-bg-1);
	color: var(--btn-fg-1);
	font-weight: bold;

}

[role=button].button:hover {
	color: var(--btn-fg-2);
	background-color: var(--btn-bg-2);
}

[role=button].button.small {
	padding: 0 .5em;
}

[role=button].alert {
	color: var(--alert-fg-1);
	background-color: var(--alert-bg-1);
}

[role=button].button.alert:hover {
	color: var(--alert-fg-1);
	background-color: var(--alert-bg-1);
	opacity: .7;
}

[role=button].disabled {
	filter: brightness(.3);
}

[role=button].selected {
	background-color: var(--btn--bg--selected);
}

[role=button].addBt span {
	display: none;
}

[role=button].addBt:before {
	color: transparent;
	text-shadow: 0 0 0 var(--btn--fg--add);
	content: "➕";
	margin-right: .4em;
}

[role=button].rmBt:before {
	color: transparent;
	text-shadow: 0 0 0 var(--btn-fg);
	content: "✕";
	margin-right: .4em;
}


.switchField {
	position: relative;
	display: flex;
	width: 100%;
}

.switchField input {
	vertical-align: middle;
}

.switchContainer {
	position: relative;
	width: 50px;
}

.switchContainer input {
	position: absolute;
	top: 10%;
	right: 10%;
	opacity: 0.001
}

.switchContainer label {
	position: relative;
	display: block;
	width: 40px;
	height: 20px;
}

.switchContainer label::before,
.switchContainer label::after {
	display: block;
	content: '';
	pointer-events: none;
}

.switchContainer label::before {
	height: 1em;
	width: 2em;
	border: solid 1px var(--btn-sw-border);
	border-radius: 1em;
	transition: background-color .3s, border-color .3s, box-shadow .3s;
}

.switchContainer label::after {
	position: absolute;
	top: 0px;
	left: 0px;
	width: .8em;
	height: .8em;
	margin-top: 0.1em;
	margin-left: 0.1em;
	background-color: var(--btn-sw-cursor);
	border-radius: 50%;
	transition: background-color .3s, transform .3s;
}

.switchContainer input:checked+label::before {
	background-color: var(--btn-sw-checked);
	border-color: var(--btn-sw-checked);
}

.switchContainer input:checked+label::after {
	background-color: var(--btn-sw-checked-cursor);
	transform: translateX(1em);
}

.switchContainer input:focus+label::before {
	box-shadow: 0 0 0 0.1em var(--btn-sw-checked);
	border-color: var(--btn-sw-checked);
}