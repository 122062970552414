.home_container {
  /* display: grid;
  grid-template-columns: 1fr 25%;
  grid-template-rows: auto auto;
  color: var(--fg-1);
  min-height: 100%;
  margin: 0;
  justify-content: center;
  line-height: 1.6; */
  /* display: flex; */
  /* flex-wrap: wrap; */
}

.home_container .row1 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.home_container .overview {
  padding: 2vh 2vw;
  flex-basis: 31rem;
  max-width: 75%;
  flex-grow: 100;
  min-width: 31rem;
}

.home_container .overview h2 {
  font-size: 4rem;
  margin: .4em .4em .4em 2vw;
}

.home_container .overview h3 {
  color: var(--fg-2);
  font-size: 3rem;
  margin: 0 .4em 1.5em 2vw;
}

.home_container .previewContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  overflow: hidden;
}

.home_container .previewContainer .col1 {
  flex-basis: 25em;
  flex-grow: 1;
  margin: 0 2vw;
}

.home_container .previewContainer .col2 {
  min-width: 18em;
  max-width: 30em;
  margin: 0 2vw;
}

.home_container .previewContainer .videoPreview {
  width: 100%;
  height: 15em;
  border: 1px solid var(--fg-3);
  border-radius: 6px;
  overflow: hidden;
}

.home_container .previewContainer .videoPreview img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.home_container .overviewLine2 {
  grid-column: 1/3;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  background-color: var(--bg-3);
  margin: 1.5em 0;
}

.home_container .overviewLine2>div {
  flex-basis: 15em;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: min(25%, 25em);
  text-align: center;
  margin: 1em;
}

.home_container .overviewLine2 svg {
  display: inline-block;
  max-width: 4em;
  max-height: 4em;
  stroke: var(--fg-1);
  fill: var(--fg-2);
  margin: auto;
}

.home_container .overviewLine2 .title {
  font-weight: bolder;
  margin: 1em;
}

.login_container>div {
  padding: 2vh 5%;
}

.login_container {
  position: relative;
  background-color: var(--bg-3);
  border-radius: 10px;
  margin: 2rem;
  max-height: 0;
  transition: max-height .5s linear;
  overflow: hidden;
  max-width: 35rem;
  flex-grow: 1;
}

.login_container a {
  cursor: pointer;
}

.login_container h2 {
  text-align: center;
  color: var(1);
  font-size: 2rem;
}

.login_container h2 {
  margin: 0 0 2em;
  font-weight: 600;
  margin-bottom: 1em;
}

.login_container .card_button {
  border-radius: 6px;
  border: none;
  outline: none;
  width: 100%;
  padding: 0 0.8rem;
  font-size: 1.1em;
  line-height: 2.2rem;
  font-weight: 500;
  margin: 3vh 0 1.2vh;
  text-align: center;
  background: var(--btn-actionCall-bg);
  transition: all 0.3s;
  opacity: 1;
  cursor: pointer;
  transition: all .3s ease-in;
}

.login_container .card_button:hover {
  opacity: 0.8;
  border-radius: 2px;
}

.login_container .card_signup {
  font-size: 0.9em;
  color: var(--fg-2);
}

.login_container .card_signup a:hover {
  color: var(--a-fg-2);
}

.login_container .card_content {
  margin-top: 4em;
  padding: 0 0 2em;
  text-align: center;
}

.login_container .form_input {
  position: relative;
  width: 100%;
  margin-top: .5em;
}

.login_container .form_input * {
  max-width: 100%;
  width: 100%;
}

.login_container label {
  color: var(--fg-1);
}

.login_container input {
  border: 0;
  padding: 0;
  z-index: 1;
  background-color: transparent;
  border-bottom: 2px solid var(--fg-3);
  font-size: 1.2em;
  line-height: 2.2em;
  color: var(--fg-1);
}

.login_container input:not(:placeholder-shown):not(:focus):invalid {
  border-color: var(--fg-alert-2);
}

.login_container input:focus {
  outline: 0;
  border-bottom-color: var(--fg-1);
}

.login_container input:valid {
  outline: 0;
  border-bottom-color: var(--fg-ok);
}


.login_container .input {
  position: relative;
}

.login_container .input input {
  padding-right: 60px;
}


.login_container .input_eye {
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  width: 1.6em;
  height: auto;
  cursor: pointer;
  z-index: 2;
  box-shadow: none;
  padding: 0;
}

.login_container .input_eye svg {
  width: 100%;
  height: auto;
  stroke: var(--fg-1);
  stroke-width: 6;
  fill: var(--bg-3);
  padding: 0;
}

.login_container .input_eye:hover {
  background-color: transparent;
}

.login_container .link {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 3;
}

.home_container .card_connectwith p {
  overflow: hidden;
  margin-top: 2em;
  font-size: 0.8rem;
}


.home_container .card_connectwith p::before,
.home_container .card_connectwith p::after {
  content: "";
  background-color: var(--fg-3);
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

max .home_container .card_connectwith p::before {
  right: 0.5em;
  margin-left: -50%;
}

.home_container .card_connectwith p::after {
  left: 0.5em;
  margin-right: -50%;
}

.card_connectwith div[role=button] {
  background-color: var(--btn-bg-1);
  border-radius: 4px;
  border: none;
  text-align: center;
  outline: none;
  width: 100%;
  padding: 0 15px;
  font-size: 1rem;
  line-height: 2.4rem;
  font-weight: 500;
  margin: 1.5rem 0 0.5rem;
  color: var(--fg-1);
  opacity: 1;
  transition: all 0.3s;
  cursor: pointer;
}

.card_connectwith div[role=button]:hover {
  opacity: .7;
  border-radius: 2px;
}

.login_container .btn_signup {
  background-color: var(--bg-ok);
}

.commingsoon .card_title {
  margin-top: 1em;
  min-height: 3em;
  text-align: center;
}

.commingsoon p {
  margin-top: 1em;
  min-height: 8em;
  text-align: center;
}

.confirmEmail p {
  line-height: 1.1;
  margin-bottom: 2em;
  text-align: center;
}


.login_container .resetPassword:hover {
  color: var(--a-fg-2);
}

.home_container .login_container .passwordConstraints {

  color: var(--fg-3);
}


.home_container .login_container .passwordConstraints .checked {
  color: var(--fg-ok);
}

.home_container .login_container .passwordConstraints .checked::before {
  position: absolute;
  content: "☑";
  top: 50%;
  left: 0%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  margin-right: .5rem;
}

.home_container .login_container .passwordConstraints li {
  position: relative;
  padding-left: 1rem;
}



@media screen and (max-width:992px) {
  body {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: "main"
      "login"
      "main";
  }
}