.projects_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.projects_container,
.projectTypes_container,
.blankProject_container {
  padding: 2vh 2vw;
}

.projects_container h2,
.projectTypes_container h2,
.blankProject_container h2 {
  text-align: center;
}

.projects_container .newButton {
  padding: 0.8rem 1rem;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.projects_container .newButton:hover {
  border-radius: 0;
}

.projects_container .inputs {
  margin: 1rem auto 0;
  width: 80%;
}

.projects_container .inputs {
  text-align: right;
}

.projects_container .inputs div {
  display: inline-block;
  vertical-align: middle;
}

.projects_container .inputs div label {
  display: inline-block;
  padding: .1rem;
  margin: .2rem;
  vertical-align: middle;
}

.projects_container .inputs select {
  margin-left: 1rem;
  border-radius: 4px 4px 0 0;
  padding: 0.5rem;
  vertical-align: middle;
}

.projects_container .pageList {
  text-align: center;
}

.projects_container .imputs>* {
  display: inline-block;
}

.projects_container .projectList {
  width: 80%;
  margin: 0 auto;
  flex-grow: 1;
  overflow: auto;
}

.projects_container .projectList>div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
}

.projects_container .projectList>div:nth-child(odd) {
  background-color: var(--bg-2);
}

.projects_container .projectList .name {
  margin-bottom: 0.8rem;
}

.projects_container .projectList [role=button]:hover {
  background-color: transparent;
}

.projects_container .projectList .description {
  line-height: 1.6;
}

.projects_container .projectList .date,
.projects_container .projectList .type {
  opacity: 0.8;
}

.projects_container .projectList .type {
  margin-top: 1rem;
}

.projects_container .projectList>div>div:nth-child(1) {
  flex-basis: 1rem;
  flex-grow: 1;
}

.projects_container .projectList>div>div:nth-child(2) {
  min-width: 12%;
  margin-left: 1rem;
  padding-left: 1rem;
}

.projectTypes_container .typeContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 6rem 1rem 0rem;
}

.projectTypes_container .typeContainer>div {
  display: inline-flex;
  align-items: center;
  width: 50rem;
  padding: 2rem;
  margin: 3rem 2rem;
}

.projectTypes_container .typeContainer>div .title {
  margin-bottom: 1rem;
}

.typeContainer .blankProject,
.typeContainer .cloneProject,
.typeContainer .templateProject {
  border: 2px solid var(--fg-1);
  border-radius: 6px;
}

.typeContainer .blankProject img,
.typeContainer .cloneProject img,
.typeContainer .templateProject img {
  width: 100%;
  height: 100%;
  margin: 1rem 3rem 1rem 1rem;
}

.projectTypes_container .typeContainer .blankProject img,
.projectTypes_container .typeContainer .cloneProject img {
  width: 9rem;
  height: auto;
}

.projectTypes_container .typeContainer .templateProject img {
  width: 5rem;
  height: auto;
}

/* create blank project */

.blankProject_container form {
  display: flex;
  flex-direction: column;
  width: 60rem;
  max-width: 60rem;
  margin: 4rem auto;
}

.blankProject_container form label {
  margin: 2rem 0;
}

.blankProject_container form input,
.blankProject_container form textarea {
  margin-bottom: 3rem;
  border-radius: 6px;
}

.blankProject_container form input {
  padding: 0.7rem;
}


.blankProject_container form div {
  border: 2px solid transparent;
  border-radius: 3px;
  width: max-content;
  filter: contrast(100%);
  padding: 0.8rem 2rem;
  transition: all 0.3s ease;
}