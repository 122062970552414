*{
	margin: 0;
	padding: 0;
}

/* Utility styles */
@import url("./reset.css");
@import url("./button.css");
@import url("./overlay.css");
@import url("./help.css");
@import url("./dialog.css");
@import url("./scrollbar.css");
@import url("./form.css");

/* Page styles */
@import url("./general.css");
@import url("./home.css");
@import url("./projects.css");
@import url("./account.css");
@import url("./workflow.css");
@import url("./edition.css");
@import url("./pagging.css");
@import url("./toolPanel.css");
@import url("./admin.css");
@import url("./componentPanel.css");
@import url("./settingsPanel.css");
@import url("./componentViewPanel.css");
@import url("./documentation.css");
@import url("./dblColumn-nav.css");
@import url("./passwordReset.css");