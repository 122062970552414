.resetPassword_container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 100%;
  font-size: 1.8rem;
  color: #ffffff;
  margin-top: 2rem;
}

.resetPassword_container h2 {
  color: #EEE;
  font-size: 4rem;
  margin: 8rem .4em .4em 2vw;
}


.resetPassword_container form {
  background-color: #465060;
  border-radius: 10px;
  margin: 40px;
  padding: 35px 20px 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  width: 320px;
  height: max-content;
  box-sizing: border-box;
  color: #fff;
  margin-top: 6em;
  margin-bottom: 1.5em;
}


.resetPassword_container form .form_input label,
.resetPassword_container form .form_input input {
  display: block;
}

.resetPassword_container form .form_input label {
  color: #dcdcdc;
  font-size: 18px;
  margin-top: 2rem;
}

.resetPassword_container form .form_input input {
  position: relative;
  width: 100%;
  margin: .5em 0;
  padding-top: 0px;
  border: 0;
  padding: 0;
  z-index: 1;
  background-color: transparent;
  border-bottom: 2px solid #969696;
  font: inherit;
  font-size: 1.2em;
  line-height: 30px;
  color: #fff;
}

.resetPassword_container form .form_input * {
  max-width: 100%;
}

.resetPassword_container form input:not(:placeholder-shown):not(:focus):invalid {
  border-color: red;
}

.resetPassword_container form input:focus+label,
.resetPassword_container form input:valid+label {
  color: #eee;
}

.resetPassword_container form input:focus {
  outline: 0;
  border-bottom-color: #eee;
}

.resetPassword_container form input:valid {
  outline: 0;
  border-bottom-color: rgb(101, 101, 188);
}


.resetPassword_container form .input {
  position: relative;
}

.resetPassword_container form .input input {
  padding-right: 60px;
}


.resetPassword_container form .input_eye {
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  width: 40px;
  height: auto;
  cursor: pointer;
  z-index: 2;
  box-shadow: none;
}

.resetPassword_container form .input_eye svg {
  width: 100%;
  height: auto;
  stroke: #dcdcdc;
  stroke-width: 6;
}

.resetPassword_container form .input_eye:hover {
  background-color: transparent;
}



.resetPassword_container form.link {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 3;
}

.resetPassword_container form a {
  cursor: pointer;
  color: #eee;
  text-decoration: none;
}

.resetPassword_container form a:hover {
  color: var(--primary);
}


.resetPassword_container form [type=submit] {
  border-radius: 6px;
  border: none;
  outline: none;
  width: 100%;
  padding: 0 15px;
  font-size: 18px;
  line-height: 36px;
  font-weight: 500;
  margin: 35px 0 35px;
  text-align: center;
  color: #fff;
  background: rgb(255, 111, 0);
  transition: all 0.3s;
  opacity: 1;
  box-shadow: 0 2px 2px -3px var(--primary);
  cursor: pointer;
  transition: all .3s ease-in;
}

.resetPassword_container form [type=submit]:hover {
  opacity: 0.8;
  border-radius: 2px;
}

.resetPassword_container .passwordConstraints {
  font-size: 1.5rem;
  color: #969696;
}

.resetPassword_container .passwordConstraints {
  font-size: 1.5rem;
  color: #969696
}

.resetPassword_container .passwordConstraints .checked {
  color: rgb(118, 197, 118);
}

.form-passwordReset .error {
  background-color: rgba(255, 0, 0, 0.3);
  border-radius: 4px;
  font-style: italic;
  color: white;
  text-align: center;
  font-size: 1.2em;
  padding: 0 .4em;
  line-height: 1.2;
}