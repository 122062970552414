form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  max-width: 40em;
  padding: 0.3em;
}

label {
  display: block;
  margin-top: 1.3em;
  margin-bottom: 0.3em;
  max-width: 100%;
  word-wrap: break-word;
  overflow: hidden;
}

label .helpBt {
  width: 1em;
  height: 1em;
  font-size: 1em;
  line-height: 1em;
  text-align: center;
  vertical-align: middle;
  margin: .5em;
  padding: 0;
  border-radius: 50%;
  border: 1px solid var(--fg-2);
  ;
}

input,
textarea,
select {
  background-color: var(--bg-1);
  color: var(--fg-1);
  border: 1px solid var(--fg-3);
  border-radius: 3px;
  font-size: 1rem;
  padding: .4em;
}

.passwordInput {
  position: relative;
  width: max-content;
}

.passwordInput .input_eye {
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  width: 1.8em;
  height: auto;
  cursor: pointer;
  z-index: 2;
  box-shadow: none;
  padding: 0;
  margin: 0 0.4em 0 0;
}

.passwordInput .input_eye svg {
  width: 100%;
  height: auto;
  stroke: var(--fg-1);
  stroke-width: 6;
  fill: var(--bg-3);
  padding: 0;
}

.switchField {
  display: flex;
  align-items: center;
  margin-top: 1.3em;
}

.switchField>label {
  flex-grow: 1;
  margin-right: .3em;
}

.resetButtonContainer {
  text-align: right;
  background-color: transparent;
}

.resetButtonContainer .button {
  background-color: transparent;
}

form .comment {
  color: var(--fg-3);
  margin: 1em;
  line-height: 1.6;
}

form .fieldConstraints {
  color: var(--fg-3);
  padding: 0;
  margin: 0.3rem 0 0 1rem;

}

form .fieldConstraints>li {
  position: relative;
  padding-left: 1.6rem;
}


form .fieldConstraints .checked {
  color: var(--fg-ok);
}

form .fieldConstraints .checked::before {
  position: absolute;
  content: "✓";
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
  font-size: 1.6rem;
}


form .customArea {
  margin: 1em;
  padding: .5em;
}

form .buttonLine {
  margin-top: 1.4em;
}