.helpButtonContainer {
	display: inline-block;
	margin: 0 1em;
	padding: 0;
	vertical-align: middle;
}

.helpButtonContainer .helpButton {
	width: 2rem;
	height: 2rem;
	text-align: center;
	font-weight: bold;
	line-height: 2.2rem;
	border-radius: 50%;
	background-color: var(--fg-3);
	color: var(--bg-1);
	padding: 0;
	margin: 0;
}

.helpButtonContainer .container {
	display: none;

}

.helpButtonContainer .helpButton.visible+._infoOverlay {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 700;
}

.helpButtonContainer .helpButton.visible+._infoOverlay .container {
	position: absolute;
	display: block;
	left: 50%;
	top: 50%;
	min-width: 300px;
	width: max-content;
	max-width: 98%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);

	background-color: var(--bg-2);
	/* border: 1px solid gray; */
	border-radius: 4px;
	padding: 1ex 1em;
}

.helpButtonContainer .container header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	position: relative;
	margin-bottom: 1em;
}

.helpButtonContainer .container header:before {
	display: inline-block;
	float: left;
	width: 2.2rem;
	height: 2.2rem;
	padding: 0;
	margin-right: 1rem;
	content: "i";
	font-size: 1.8rem;
	font-weight: bold;
	line-height: 1.3;
	text-align: center;
	border: 2px solid var(--fg-1);
	border-radius: 100%;
}

.helpButtonContainer .container header .title {
	font-size: 2rem;
	font-weight: bold;
	min-height: 1.4rem;
}

.helpButtonContainer .container header .close {
	position: absolute;
	right: 0;
	top: -2em;
	border: 1px solid var(--fg-3);
	border-radius: 6px;
	background-color: var(--bg-2);
	padding: .2em .5em;

}

.helpButtonContainer .container .content {
	max-height: 70vh;
	font-size: 2rem;
	padding: 3rem;
	overflow: auto;
}

.helpButtonContainer .container .content ul li {
	margin: .8em 0;
}

@media only screen and (min-width: 650px) {
	.helpButtonContainer .helpButton.visible+._infoOverlay .container {
		max-width: 650px;
	}
}