.settings_panel h2 {
  display: flex;
  align-items: center;
  margin: 0 0 .5em 0;
  padding: .2em;
  font-size: 1.2rem;
  text-align: center;
  background-color: var(--bg-3);
}

.settings_panel h2>span {
  flex-grow: 1;
  text-align: center;
}

.settings_panel h2>[role=button] {
  background-color: transparent;
  margin: 0 0.4em .2em;
  padding: .2em;
}


.settings_panel [role=button] {
  font-size: .9rem;
  margin: 0 .2em;
  padding: .3em;
}

.settings_panel.hidden{
  display:  none;
}

aside.settings_panel{
  min-width: 20%;
}