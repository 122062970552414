aside.componentPanel {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	max-height: 100%;
	min-width: 20%;
}

aside.componentPanel.hidden{
	display: none;
}

aside.componentPanel h2 {
	width: 100%;
	margin: 0 0 .5em 0;
	padding: .2em .4em;
	font-size: 1.2rem;
	text-align: center;
	background-color: var(--bg-3);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden
}

aside.componentPanel img {
	position: relative;
	width: 66%;
	height: 66%;
	margin: 17%;
	object-fit: contain;
}

aside.componentPanel .components>.container>div {
	margin: 0;
	padding: 0;
}

aside.componentPanel .components>.container>div .iconContainer {
	--panelSize: 2em;
	position: relative;
	width: var(--panelSize);
	height: var(--panelSize);
	margin: .2em auto;
}

aside.componentPanel .components>.container .inout .iconContainer {
	background-color: #E893CF;
	border-radius: 50%;
}

aside.componentPanel .components>.container .inout .name {
	font-size: .8rem;
	line-height: 1.1;
}

aside.componentPanel .components>.container .operation .iconContainer {
	background-color: #EA906C;
	border-radius: 50%;
}

aside.componentPanel .components>.container .data .iconContainer {
	background-color: #526D82;
	border-radius: 50%;
}

aside.componentPanel .components>.container .visualization .iconContainer {
	background-color: #116D6E;
	border-radius: 50%;
}

aside.componentPanel .tools {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	width: 100%;
	border-bottom: 1px solid var(--separator);
	padding-bottom: 2rem;
	margin-bottom: 2rem;
}

aside.componentPanel .components {
	width: 100%;
	overflow-y: auto;
	flex-grow: 1;
	height: 4em;
}

aside.componentPanel .components>.container {
	position: relative;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(4em, 1fr));
	align-items: first baseline;
	margin-bottom: 0.7em;
	width: 99%;
	border-bottom: 1px solid var(--separator);
}

aside.componentPanel .components .title {
	color: var(--fg-2);
	font-weight: bold;
	margin-left: 0.7em;
	margin-bottom: 0.5em;
}

aside.componentPanel .tools>div {
	position: relative;
	max-width: 4em;
	text-align: center;
	margin: .4rem;
}

aside.componentPanel .components>.container>div {
	position: relative;
	flex-grow: 1;
	max-width: 4em;
	min-height: 4em;
	text-align: center;
	margin: .4rem;
}



aside.componentpanel .components>.container>div.selected .iconContainer::after {
	display: block;
	content: "";
	position: absolute;
	top: -4px;
	right: -4px;
	bottom: -4px;
	left: -4px;
	border: 2px solid var(--selection);
	border-radius: 50%;
}
